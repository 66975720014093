import { Link } from "react-router-dom";
import './Home.css';
import immaginePrincipale from '../../Assets/Img/Merendels.jpg';
import security from '../../Assets/Img/merendels_CyberSecuriry-min.jpg'
import IOT from '../../Assets/Img/merendels_iot-min.jpg'
import software from '../../Assets/Img/merendels_software.jpg'
import RegioneLazio from '../../Assets/Img/regione-lazio-logo.png'
import FareLazio from '../../Assets/Img/fare-lazio-logo.png'
import STMP from '../../Assets/Img/STMP-1.jpg'


function Home() {

  return (
    <div>

      <div className="main-content">
        <img id="immaginePrincipale" src={immaginePrincipale} alt="immaginePrincipale" />
        <h1 className="main-text">TOCCA CON MANO L'INNOVAZIONE</h1>
      </div>

      <div className="cards-container">
        <div className="card">
          <Link to="/Internet-Of-Things">
            <button className='card-icon card-icon-blue'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-lightning-fill" viewBox="0 0 16 16" style={{ color: 'white' }}>
                <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641z" />
              </svg></button>
          </Link>
          <h2 className="card-title">Internet Of Things</h2>
          <p className="card-description">Sviluppiamo prodotti e soluzioni originali, nell'ambito della progettazione Hardware e Software di dispositivi IOT. Rendi il tuo Business intelligente.</p>
          <Link to="/Internet-Of-Things"><button className="card-btn card-btn-blue">SCOPRI DI PIU'</button></Link>
        </div>
        <div className="card">
          <Link to="/Cyber-Security">
            <button className='card-icon card-icon-green'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-shield-shaded" viewBox="0 0 16 16" style={{ color: 'white' }}>
                <path d="M8 14.933a1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56" />
              </svg></button>
          </Link>
          <h2 className="card-title">CyberSecurity PMI</h2>
          <p className="card-description">Merendels ha sviluppato soluzioni per la gestione della CyberSecurity e la IT Governance delle PMI. I tuoi dati sempre al sicuro.</p>
          <Link to="/Cyber-Security"><button className="card-btn card-btn-green">SCOPRI DI PIU'</button></Link>
        </div>
        <div className="card">
          <Link to="/Sviluppo-Software">
            <button className='card-icon card-icon-yellow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-laptop" viewBox="0 0 16 16" style={{ color: 'white' }}>
                <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5" />
              </svg></button>
          </Link>
          <h2 className="card-title">Sviluppo Software</h2>
          <p className="card-description">Siamo in grado di concentrare risorse ed energie per realizzare Siti Web e App su misura per te. La tua attività online con un click.</p>
          <Link to="/Sviluppo-Software"><button className="card-btn card-btn-yellow">SCOPRI DI PIU'</button></Link>
        </div>
        <div className="card">
          <Link to="/Blockchain">
            <button className='card-icon card-icon-purple'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-currency-bitcoin" viewBox="0 0 16 16" style={{ color: 'white' }}>
                <path d="M5.5 13v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.5v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.084c1.992 0 3.416-1.033 3.416-2.82 0-1.502-1.007-2.323-2.186-2.44v-.088c.97-.242 1.683-.974 1.683-2.19C11.997 3.93 10.847 3 9.092 3H9V1.75a.25.25 0 0 0-.25-.25h-1a.25.25 0 0 0-.25.25V3h-.573V1.75a.25.25 0 0 0-.25-.25H5.75a.25.25 0 0 0-.25.25V3l-1.998.011a.25.25 0 0 0-.25.25v.989c0 .137.11.25.248.25l.755-.005a.75.75 0 0 1 .745.75v5.505a.75.75 0 0 1-.75.75l-.748.011a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25zm1.427-8.513h1.719c.906 0 1.438.498 1.438 1.312 0 .871-.575 1.362-1.877 1.362h-1.28zm0 4.051h1.84c1.137 0 1.756.58 1.756 1.524 0 .953-.626 1.45-2.158 1.45H6.927z" />
              </svg></button>
          </Link>
          <h2 className="card-title">Blockchain</h2>
          <p className="card-description">Realizziamo la tua crypto moneta personale, creando contratti intelligenti grazie alla potenza di Ethereum. Crea la tua crypto aziendale.</p>
          <Link to="/Blockchain"><button className="card-btn card-btn-purple">SCOPRI DI PIU'</button></Link>
        </div>
      </div>

      <div className='container-security'>
        <img src={security} alt="Cyber Security" />
        <div id='container-dati-sicuri'>
          <h2>I tuoi dati sempre al sicuro!</h2>
          <p>Merendels ha sviluppato un'offerta per la gestione della CyberSecurity e la IT Governance mirata alle piccole e medie imprese. In un mondo sempre più digitale e tecnologico, la sicurezza informatica dei propri dati aziendali è una caratteristica sempre più necessaria. Merendels ha trovato la soluzione efficace a questo problema.</p>
          <Link to="/Cyber-Security"><button>SCOPRI DI PIU'</button></Link>
        </div>
      </div>

      <div className='container-IOT'>
        <div id='container-soluzioni-intelligenti'>
          <h2>Sviluppiamo soluzioni IOT intelligenti</h2>
          <p>L’ Internet Of Things, o la capacità di rendere intelligenti gli oggetti, è una delle nostre specialità. In questa tecnologia ogni oggetto è connesso all’altro grazie a molteplici sistemi di comunicazione. Possiamo creare servizi intelligenti per ogni vostra esigenza.</p>
          <Link to="/Internet-Of-Things"><button>SCOPRI DI PIU'</button></Link>
        </div>
        <img src={IOT} alt="IOT" />
      </div>

      <div className='container-software'>
        <img src={software} alt="Cyber Security" />
        <div id='container-software-misura'>
          <h2>Software su misura!</h2>
          <p>C,  C++,  C#,  JAVA,  JAVA SCRIPT, PHP, PYTHON, ANDROID. Il processo è diviso in fasi iterative ed incrementali per garantire un ottimo livello di qualità dei requisiti e la realizzazione nei tempi pianificati. Realizziamo Software sia per aziende che per privati, nei seguenti campi di applicazione:  Web, Desktop, Ios, Android.</p>
          <Link to="/Sviluppo-Software"><button>SCOPRI DI PIU'</button></Link>
        </div>
      </div>

      <div className='container-consulenza'>
        <h2>La Tua Azienda...come non l'hai mai vista!</h2>
        <p>Porta la tua azienda ad un altro livello, assicurandoti di avere le migliori soluzioni presenti sul <br /> mercato. Inizia oggi a scoprire tutti i vantaggi che solo Merendels ti può dare.</p>
        <h2>Ottieni i Migliori Risultati!</h2>
        <a href="mailto:info@merendels.com"
          className='btn-consulenza'>
          <strong>RICHIEDI CONSULENZA</strong>
        </a>
      </div>


      <div className='container-virtual-logistic-company'>
        <h2>Virtual logistic company</h2>
        <p>L’idea che sottende il progetto è quella di virtualizzare tutte le componenti di una <br /> società di trasporti realizzando un’immagine digitale su cloud di tutti i servizi e sistemi <br /> necessari al funzionamento della società stessa.</p>
        <p>Allo sviluppo dei moduli canonici (amministrazione, finanza, gestione clienti, ecc) <br /> verranno sviluppati i moduli di acquisizioni dati prodotti dai sensori presenti sulla <br /> flotta di autoveicoli aziendali, verranno anche sviluppati moduli di acquisizioni in <br /> tempo reale di informazioni esterne (per esempio condizioni atmosferiche, viabilità, <br /> criminalità, eventi, dati da interporti, porti, aeroporti e ferrovie) per sviluppare nuovi <br /> servizi per migliorare l’offerta della società nei confronti dei clienti e ottimizzare i costi <br /> aziendali.</p>
        <p>La realizzazione di questo progetto è stato finanziato <br />
          dalla Regione Lazio tramite l’agenzia FareLazio</p>
        <img className='img-regione-lazio' src={RegioneLazio} alt="Regione Lazio" />
        <img className='img-fare-lazio' src={FareLazio} alt="Fare Lazio" />
      </div>

      <div className='container-stmp'>
        <h2>STMP</h2>
        <div className='card-stmp'>
          <img src={STMP} alt="stmp" />
          <p>Progetto cofinanziato dall’Unione Europea, dallo Stato Italiano e dalla Regione Campania nell’ambito del POR Campania FERS 2014/2020 approvato con D.D.n 647 del 30/12/2018</p>
          <hr />
        </div>
      </div>

    </div>
  );
}

export default Home;
